<div app-looma-page [pageTitle]="pageTitle" pageIcon="playlist_play"
     style="height: 100%" [headerSideTpl]="headerSideTpl" *ngIf="retailerPromoPeriod">

    <div style="display: flex; flex-direction: row; height: 100%; width: 100%">
        <div style="flex: 1; display: flex; position: relative;">
            <div
                style="display: flex; overflow-y: hidden; position: absolute; top:0; left:0; right:10px; bottom:0; padding:8px 0 ; gap:10px;">
                <div class="mat-elevation-z3" *ngFor="let ds of controller.itemDataSources"
                     style="height:100%; min-width: 300px;flex:1">
                    <ng-container [ngTemplateOutlet]="tplProductList"
                                  [ngTemplateOutletContext]="{dataSource:ds}"></ng-container>
                </div>
            </div>

        </div>
        <div style="z-index: 1; min-width: 500px !important; max-width: 25%; " class="mat-elevation-z3">
            <ng-container [ngTemplateOutlet]="tplAvailableProductList"
                          [ngTemplateOutletContext]="{dataSource:controller.defaultItemDataSource}"></ng-container>
        </div>

    </div>
</div>

<ng-template let-dataSource="dataSource" #tplProductList>
    <looma-grid *ngIf="dataSource" [dataSource]="dataSource" fxFill class="device_slot_list"
                [title]="dataSource.dataSourceLabel"
                [class.slot_list]="dataSource.hasUnassignableItems" [dragDropController]="dataSource.ddController">
        <ng-template dragPreview let-dataSource="dataSource" let-selectedItems="selectedItems">
            <div>
                <div *ngFor="let bp of selectedItems" class="dragged_item">
                    <div>{{ bp.getDisplayName() }}</div>
                </div>
            </div>
        </ng-template>
    </looma-grid>
</ng-template>

<ng-template let-dataSource="dataSource" #tplAvailableProductList>
    <looma-grid *ngIf="dataSource" [dataSource]="dataSource" fxFill class="device_slot_list"
                [title]="dataSource.dataSourceLabel"
                [class.slot_list]="dataSource.hasUnassignableItems" [dragDropController]="dataSource.ddController">
        <ng-template dragPreview let-dataSource="dataSource" let-selectedItems="selectedItems">
            <div>
                <div *ngFor="let bp of selectedItems" class="dragged_item">
                    <div>{{ bp.getDisplayName() }}</div>
                </div>
            </div>
        </ng-template>

        <ng-template gridColumnHeader="upc_code" let-dataSource="dataSource" let-label="label">
            <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%;">
                <span fxFlex>{{ label }}</span>
                <div style="display: flex">
                    <button class="small filter_active_badge" mat-icon-button (click)="openFilters($event)">
                        <mat-icon matBadgeColor="warn" [matBadge]="dataSource.hasLocalFilters() ? '!' : null"
                                  matBadgePosition="above after">filter_list
                        </mat-icon>
                    </button>
                </div>
            </div>
        </ng-template>

    </looma-grid>
</ng-template>

<ng-template #tplVarietalFilter>
    <div class="filters_dialog mat-elevation-z3" fxLayout="column" style="background-color: white">
        <!-- Varietal Selection -->
        <mat-form-field>
            <mat-label>Varietal</mat-label>
            <mat-select multiple (selectionChange)="getDefaultDataSource().filter($event)"
                        [value]="getDefaultDataSource().selectedVarietals">
                <mat-option *ngFor="let r of allVarietals" [value]="r">{{ r.name }}</mat-option>
            </mat-select>
        </mat-form-field>

        <!-- Search Input -->
        <mat-form-field>
            <mat-label>Search</mat-label>
            <input
                matInput
                placeholder="Type to search..."
                [formControl]="searchControl">
        </mat-form-field>
    </div>
</ng-template>

<ng-template #headerSideTpl>
    <button mat-raised-button color="warn" (click)="generateCampaigns()"
            [disabled]="isBusy || !canGenerate()" class="mr-16">
        <mat-icon>
            hub
        </mat-icon>
        Generate
    </button>
    <button mat-raised-button (click)="manageSlotAssignements()"
            [disabled]="isBusy" class="mr-16">
        <mat-icon>checklist</mat-icon>
        Manage Slot Assignments
    </button>
</ng-template>
import {BaseModel} from '@looma/shared/models/base_model';
import {Retailer} from '@looma/shared/models/retailer'
import {DeviceSlot} from '@looma/shared/models/device_slot'
import {DeviceSlotType} from '@looma/shared/models/device_slot_type'
import {DeviceApp} from "@looma/shared/models/device_app";
import {ProductCategory} from "@looma/shared/models/product_category";
import {Store} from "@looma/shared/models/store";
import {BrandProductSubmission} from "@looma/shared/models/retailer_promo_period_submission";

export class RetailerPromoProgram extends BaseModel {
    public static readonly programTypesMap = {
        Thematic: "Thematic",
        BrandFocus: "Brand Focus",
        DigitalSomm: "Digital Somm"
    }

    public static readonly availableFlexibilities = {
        ChainWide: "Chain Wide",
        Segmented: "Segmented"
    }

    public static readonly availableAirtimeTypes = {
        Standardized: "Standardized",
        Flexible: "Flexible"
    }

    public static readonly availableCostStructures = {
        CostPerCampaign: "Cost per campaign",
        CostPerStore: "Cost per store",
        CostPerRec: "Cost per rec",
        CostPerProduct: "Cost per product",
    }

    public static readonly availableProductSegmentation = {
        NoSegmentation: "No Segmentation",
        ProductSegments: "Product Segments",
    }

    id: string;
    retailer: Retailer;
    name: string;
    deviceSlotTypes: DeviceSlotType[];
    unAssignedDeviceSlots: DeviceSlot[];
    kioskDeviceApp: DeviceApp;
    active: boolean;
    programType: string;
    flexibility: string;
    campaignSlotsPerPeriod: number;
    maxFeaturedItemsPerCampaign: number;
    productCategory: ProductCategory;
    coverImageDownloadUrl: string;
    programSummaryAttachmentDownloadUrl: string;
    segments: string[];
    airtimeType: string;
    airtimeSegments: string[];
    distributionRequirement: number;
    description: string;
    costStructure: string;
    programMetrics: ProgramMetrics;
    productSegmentation: string;
    productSegments: string[];

    campaignVisibleInLoop: boolean
    cprEnabled: boolean
    executionReportingEnabled: boolean
    interestCollectionEnabled: boolean
    changeoverSettings?: RetailerPromoProgramChangeoverSettings
    assignedDeviceSlotIds: string[]
    numberOfStores: number
    stores: Store[]


    assign(input: any): this {
        super.assign(input);
        this.assignTypedObject(input, 'retailer', Retailer);
        this.assignTypedObject(input, 'productCategory', ProductCategory);
        this.assignTypedObject(input, 'kioskDeviceApp', DeviceApp);
        this.assignTypedArray(input, 'deviceSlotTypes', DeviceSlotType);
        this.assignTypedArray(input, 'unAssignedDeviceSlots', DeviceSlot);
        this.assignTypedArray(input, 'stores', Store);
        this.changeoverSettings = input['changeoverSettings'] || {}
        return this;
    }

    get storeCount(): number {
        return this.stores?.length != 0 ? this.stores.length : this.numberOfStores
    }
}

export interface RetailerPromoProgramFeedFilter {
    id?: string
    ids?: string[]
    query?: string
    cursor?: string
    retailerIds?: string[]
}

export interface RetailerPromoProgramMutationInput {
    id: string
    kioskDeviceAppId?: string
    name?: string
    retailerId?: string
    active?: boolean
    programType?: string
    flexibility?: string
    campaignSlotsPerPeriod?: number
    maxFeaturedItemsPerCampaign?: number
    productCategoryId?: string
    coverImageFirebaseKey?: string;
    programSummaryFirebaseKey?: string;
    segments?: string[]
    distributionRequirement?: number;
    airtimeType?: string
    airtimeSegments?: string[]
    description: string
    costStructure: string;
    campaignVisibleInLoop?: boolean
    cprEnabled?: boolean
    interestCollectionEnabled?: boolean
    executionReportingEnabled?: boolean
    changeoverEnabled?: boolean
    changeoverNumDays?: number
    productSegmentation?: string
    productSegments?: string[]
    numberOfStores?: number
}

export interface ProgramMetrics {
    CategoryLiftLast12Months
    FullFunnelROASLast12Months
    PercEngagementLast12Months
}

export interface RetailerPromoProgramChangeoverSettings {
    enabled: boolean
    numDays: number
}

export type SegmentNameToProductMap = {
    [segmentName: string]: BrandProductSubmission[];
}

export type ProductToSegmentIndex = {
    [productId: number]: string;
}

import {BaseModel} from '@looma/shared/models/base_model';
import {RetailerPromoPeriod} from "@looma/shared/models/retailer_promo_periods";
import {ProductToSegmentIndex, RetailerPromoProgram} from "@looma/shared/models/retailer_promo_program";
import {
    BrandProductSubmission,
    RetailerPromoPeriodSubmission
} from "@looma/shared/models/retailer_promo_period_submission";
import {BrandPartner} from "@looma/shared/models/brand_partner";
import gql from "graphql-tag";
import {RetailerScheduleSubmissionSelection} from "@looma/shared/models/retailer_schedule_submission_selection";
import {BrandProduct} from "@looma/shared/models/brand_product";

export class RetailerPromoPeriodSubmissionSchedule extends BaseModel {
    id: string;
    retailerPromoPeriod: RetailerPromoPeriod
    retailerPromoProgram: RetailerPromoProgram
    openForSubmission: boolean
    readyForReview: boolean
    dueDate: Date
    campaignCost: number
    theme: string
    segments: SegmentCost[]
    submissions: RetailerPromoPeriodSubmission[]
    preferredProduct: PreferredProducts
    retailerSelection: RetailerScheduleSubmissionSelection

    public static getDaysUntilDueDate(dueDate: Date): string {
        const now = new Date();

        now.setHours(0, 0, 0, 0);
        dueDate.setHours(0, 0, 0, 0);

        if (dueDate.getTime() === now.getTime()) {
            return "Last day to submit";
        }

        const differenceInTime = dueDate.getTime() - now.getTime();
        const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
        return `${differenceInDays} days to submit`;
    }

    isDeadlineDue(): boolean {
        if (this.dueDate) {
            const now = new Date()
            return now.getTime() > this.dueDate.getTime()
        } else {
            return false
        }
    }

    isOpenedForSubmissions(): boolean {
        return !this.isDeadlineDue() && this.openForSubmission == true;
    }

    assign(obj: any): this {
        super.assign(obj);
        this.assignTypedObject(obj, 'retailerPromoPeriod', RetailerPromoPeriod);
        this.assignTypedObject(obj, 'retailerPromoProgram', RetailerPromoProgram);
        this.assignTypedObject(obj, 'retailerSelection', RetailerScheduleSubmissionSelection);
        this.assignTypedArray(obj, 'submissions', RetailerPromoPeriodSubmission);
        this.assignDate(obj, 'dueDate')
        return this;
    }

    allPreferredProducts(): BrandProduct[] {
        if (!this.preferredProduct || !this.preferredProduct.segments) {
            return [];
        }

        return this.preferredProduct.segments.reduce((acc, segment) => {
            return acc.concat(segment.products);
        }, [] as BrandProduct[]);
    }

    productsToSegmentsIndex(brandPartnerLoomaId: string): ProductToSegmentIndex {
        const productsToSegmentsIdx: ProductToSegmentIndex = {};
        this.preferredProduct?.segments?.forEach(segment => {
            const {segmentName, products} = segment;
            products
                .filter(({brand_partner}) => brand_partner.looma_id.startsWith(brandPartnerLoomaId))
                .map(({id, name, upc_code, thumb_url}) =>
                    new BrandProductSubmission(
                        Number(id),
                        name,
                        upc_code,
                        thumb_url,
                        true,
                    )
                )
                .forEach(product => {
                    productsToSegmentsIdx[product.id] = segmentName;
                });
        });
        return productsToSegmentsIdx;
    }

    segmentsArray(brandPartnerLoomaId: string): { segmentName: string; productIds: number[] }[] {
        const segmentsMap = new Map<string, number[]>();
        Object.entries(this.productsToSegmentsIndex((brandPartnerLoomaId))).forEach(([productId, segmentName]) => {
            if (!segmentsMap.has(segmentName)) {
                segmentsMap.set(segmentName, []);
            }
            segmentsMap.get(segmentName).push(Number(productId));
        });

        return Array.from(segmentsMap, ([segmentName, productIds]) => ({
            segmentName: segmentName,
            productIds: productIds,
        }));
    }

}

export interface PreferredProducts {
    segments: PreferredProductSegment[]
}

export interface PreferredProductSegment {
    segmentName: string
    products: BrandProduct[]
}

export interface SegmentCost {
    groupName: string
    segments: SegmentCostEntry[]
}

export interface SegmentCostEntry {
    segmentName: string
    segmentCost: number
}

export interface RetailerPromoPeriodSubmissionScheduleMutationInput {
    id?: string
    promoPeriodId?: string
    promoProgramId?: string
    openForSubmission?: boolean
    readyForReview?: boolean
    dueDate?: string
    campaignCost?: number
    theme?: string
    segments?: SegmentCost[]
}

export interface SubmissionScheduleCriteria {
    id?: string
    promoPeriodId?: string
    promoProgramId?: string
}


export class BrandSubmissionStatus extends BaseModel {
    promoProgram: RetailerPromoProgram
    submissionStatuses: BrandSubmissionStatusEntry[]
    openedSchedules: RetailerPromoPeriodSubmissionSchedule[]

    assign(obj: any): this {
        super.assign(obj);
        this.assignTypedObject(obj, 'promoProgram', RetailerPromoProgram);
        this.assignTypedArray(obj, 'submissionStatuses', BrandSubmissionStatusEntry);
        this.assignTypedArray(obj, 'openedSchedules', RetailerPromoPeriodSubmissionSchedule);
        return this;
    }
}

export class BrandSubmissionStatusEntry extends BaseModel {
    brandPartner: BrandPartner
    status: string
    submissions: RetailerPromoPeriodSubmission[]

    getId(): number {
        return Number(this.brandPartner.id);
    }

    assign(obj: any): this {
        super.assign(obj);
        this.assignTypedObject(obj, 'brandPartner', BrandPartner);
        this.assignTypedArray(obj, 'submissions', RetailerPromoPeriodSubmission)
        return this;
    }

}

const PROMO_PERIOD_GQL_FIELDS = gql`
fragment PromoPeriodFields on RetailerPromoPeriod {
    id
    name
    status
    startDate
    endDate
    activateAt
    status
    promoSchedule{
        id        
    }
    promoPrograms {
        id
        name
        active
        programType
        flexibility
        campaignSlotsPerPeriod
        maxFeaturedItemsPerCampaign
        segments   
        airtimeSegments        
    }
    retailer {
        id
        retailer_name
    }
}
`

export const QUERY_PROMO_PERIOD = gql`
${PROMO_PERIOD_GQL_FIELDS}
query retailerPromoPeriod($id:ID!) {
  retailerPromoPeriod(id:$id) {
    ...PromoPeriodFields 
  }
}    
`;


export const QUERY_PROGRAM_SUBMISSION_SCHEDULE = gql`
${PROMO_PERIOD_GQL_FIELDS}
query programSubmissionSchedule($criteria: SubmissionScheduleCriteria!, $submissionType: String!) {
 programSubmissionSchedule(criteria:$criteria) {  
    id
    retailerPromoPeriod {
      ...PromoPeriodFields
    }
    retailerPromoProgram {
      id
      name
      programType
      flexibility
      campaignSlotsPerPeriod
      maxFeaturedItemsPerCampaign
      productSegmentation
      productSegments
      productCategory {
        id
        category_name
      }
      segments
    }
    openForSubmission
    readyForReview
    dueDate
    campaignCost
    theme
    preferredProduct {
        segments {
            segmentName
            products {
                id
                name
                upc_code
                brand_partner {
                    id
                    name
                    product_categories {
                        id
                        category_name
                    }
                    parentBrand {
                        id
                        name
                    }
                }
            }
        }
    }    
    segments {
        groupName 
        segments {
            segmentName
            segmentCost
        }        
    }
    submissions(submissionType: $submissionType) {
      id
      brandPartner {
        id
        name
        logoUrl
        uptimeBrand
      }
      featuredProducts {
        id
        name
        upc
        thumbUrl
        uptimeProduct
      }
      submittedByBrand {
        id
        name
      }
      segments {
        segmentName
        segmentCost
      }
      airtime {
        segmentName
        segmentCost 
      }
      submittedByUser
      note
      status
      createdAt      
    }
  }    
}
`;

export const MUTATION_UPSERT_SUBMISSION_SCHEDULE = gql`
mutation upsertSubmissionSchedule($op: MutationOperation!, $input: RetailerPromoPeriodSubmissionScheduleMutationInput!) {
     result: upsertSubmissionSchedule(op: $op, input: $input) {
        success
        message
        retailerPromoPeriodSubmissionSchedule {
          id
          dueDate
          campaignCost
          openForSubmission
          segments {
            groupName
            segments {
                segmentName
                segmentCost
            }
          }
        }
      }  
}  
`;


export const MUTATE_SCHEDULE_PREFERRED_PRODUCTS = gql`
mutation upsertPreferredProducts($submissionScheduleId: ID!, $input: PreferredProductsInput!) {
     result: upsertPreferredProducts(submissionScheduleId: $submissionScheduleId, input: $input) {
        success
        message
        retailerPromoPeriodSubmissionSchedule {
          id
          dueDate
          campaignCost
          openForSubmission
          preferredProduct {
            segments {
                segmentName
                products {
                    id
                    name
                    upc_code
                }
            }            
          }
          segments {
            groupName
            segments {
                segmentName
                segmentCost
            }
          }
        }
      }  
}  
`;


export const MUTATION_ADD_SUBMISSION = gql`
mutation upsertProgramSubmission($input: ProgramSubmissionInput!) {
  upsertProgramSubmission(input: $input) {
    id
    brandPartner {
        id
        name
        logoUrl
        uptimeBrand
        products {
            id
            name
            upc
            thumbUrl
        }
    }
    featuredProducts {
        id                            
        name
        upc
        thumbUrl
        uptimeProduct
        brandPartnerName
    }
    segments {        
        segmentName
        segmentCost        
    }
    airtime {
        segmentName
        segmentCost
    }
    note
    status
    createdAt
  }
}
`;


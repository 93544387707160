import {Component, OnInit} from '@angular/core';
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {ModelListPageComponent} from "../../../shared/model_list_page_component";
import {ColumnSortInfo, CursorFilter, SortDirection} from "@looma/shared/types/cursor_filter";
import {BrandPromoCampaignReport, IBrandCampaignPerformance} from "@looma/shared/models/brand_promo_campaign_report";
import {ModelDataSource} from "../../../layout/components/looma-grid/grid-data-source";
import {Observable} from "rxjs";
import {LayoutService} from "../../../services/layout.service";
import {ApiDataService} from "../../../services/api-data.service";
import {CursorFeed} from "@looma/shared/cursor_feed";
import {NamedValue} from "@looma/shared/types/named_value";
import {debounceTime, flatMap, takeUntil} from "rxjs/operators";
import {Utils} from "@looma/shared/utils";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {
    CampaignReportEmailDialogComponent
} from "../campaign-report-email-dialog/campaign-report-email-dialog.component";
import {BrandPromoCampaign} from "@looma/shared/models/brand_promo_campaign";
import {
    BrandCampaignEditDialogComponent
} from "../../retailer-promo-campaigns/components/brand-campaign-edit-dialog/brand-campaign-edit-dialog.component";
import {
    BRAND_CAMPAIGN_GQL_FIELDS
} from "../../retailer-promo-campaigns/components/promo-schedule-program-overview/promo-schedule-program-overview.component";
import {RetailerPromoProgram} from "@looma/shared/models/retailer_promo_program";
import {Retailer, RetailerFeedFilter} from "@looma/shared/models/retailer";
import {FormBuilder, FormGroup} from "@angular/forms";
import {TableColumnSpec} from "../../../layout/components/looma-grid/table-column";
import {ReportShareUrlComponent} from "../report-share-url/report-share-url.component";
import {ConfirmDialogComponent} from "@looma/shared/components/confirm-dialog/confirm-dialog.component";

@LifecycleHooks()
@Component({
    selector: 'app-campaign-reports-list',
    templateUrl: './campaign-reports-list.component.html',
    styleUrls: ['./campaign-reports-list.component.scss']
})
export class CampaignReportsListComponent extends ModelListPageComponent<BrandPromoCampaignReport, CursorFilter> implements OnInit {

    form: FormGroup;

    retailersOfInterest = ["1", "11", "2", "7"]

    allRetailers: Retailer[]
    selectedRetailers: Retailer[]
    availablePrograms: RetailerPromoProgram[]

    private headersMap = {
        retailerName: 'Retailer',
        brandName: 'Brand',
        brandCampaignName: "Campaign",
        numberOfStores: 'Number of stores',
        promoPeriodName: 'Promo Period',
        awarenessValue: 'Awareness',
        discoveryValue: 'Discovery',
        conversionValue: 'Conversion',
        loyaltyValue: 'Loyalty',
        overlapValue: 'Overlap',
        campaignCost: 'Cost',
        campaignCostDiscount: 'Discount',
        campaignRegions: 'Regions',
        fullFunnelValue: 'Full-Funnel Value',
        roasMultiplier: 'FFiROAS Multiplier',
        discountedRoasMultiplier: 'Discounted FFiROAS Multiplier',
        reportUrl: 'Report URL'
    };

    constructor(
        public svcLayout: LayoutService,
        private svcApi: ApiDataService,
        private fb: FormBuilder,
        public dialog: MatDialog
    ) {
        super(BrandPromoCampaignReport);

        this.form = this.fb.group({
            start: [''],
            end: [''],
            retailers: [],
            programs: [''],
            brand: [''],
            campaign: ['']
        });

        const formControlValueChanges = (controlName, callback) => {
            return this.form.get(controlName).valueChanges.pipe(debounceTime(300), takeUntil(Utils.onDestroy(this)))
                .subscribe(callback);
        };

        formControlValueChanges('retailers', retailerIds => {
            if (!retailerIds || retailerIds.length === 0) {
                this.selectedRetailers = this.allRetailers;
            } else {
                this.selectedRetailers = this.allRetailers.filter(r => retailerIds.includes(r.id));
            }
            this.availablePrograms = this.getRetailerPrograms(this.selectedRetailers);
            this.dataSource.applyFilter({
                retailerIds: this.selectedRetailers ? this.selectedRetailers.map(r => String(r.id)) : null
            });
        });

        formControlValueChanges('programs', programIds => {
            this.dataSource.applyFilter({
                programIds: programIds && programIds.length > 0 ? programIds : null
            });
        });

        const performDebouncedSearch = () => {
            this.performDateRangeSearch();
        };

        formControlValueChanges('start', performDebouncedSearch);
        formControlValueChanges('end', performDebouncedSearch);
    }

    ngOnInit(): void {
        this.svcApi.getRetailersFeed({
            ids: this.retailersOfInterest
        } as RetailerFeedFilter).pipe(takeUntil(Utils.onDestroy(this))).subscribe(result => {
            this.allRetailers = result.data
            this.availablePrograms = this.getRetailerPrograms(this.allRetailers)
        })
    }

    getRetailerPrograms(retailers: Retailer[]) {
        return retailers.reduce((acc, r) => acc.concat(r.promoPrograms), [])
    }

    initDataSource(): ModelDataSource<BrandPromoCampaignReport, CursorFilter> {
        return new CampaignReportDataSource(this.svcApi, this);
    }

    get dataSource(): CampaignReportDataSource {
        return super.dataSource as CampaignReportDataSource;
    }

    doApprove(id: string) {
        this.svcLayout.openDialogForResult(ConfirmDialogComponent, {
            data: {
                message: `Are you sure you want to approve the report?`
            }
        }).subscribe(confirmed => {
            if (confirmed == "positive") {
                this.svcApi.approveCampaignReport(id).pipe(
                    takeUntil(Utils.onDestroy(this))
                ).subscribe((resp: BrandPromoCampaignReport) => {
                    this.svcLayout.showSnackMessage(resp ? `Campaign report approved` : 'Failed to approve report');
                    if (resp) {
                        const item = this.dataSource.data.find(s => {
                            return s.getId() == id
                        })
                        item.getData().cprStatus = 'reviewed'
                        this.dataSource.replaceItem(item.getData())
                    }
                });
            } else {
                console.log("negative")
            }
        })
    }


    onParentCompanyFilterChanged(event: NamedValue) {
        this.dataSource.applyFilter({parentCompanyId: event ? event.value : null})
    }

    onFeatureBrandFilterChanged(brandCampaign: NamedValue) {
        this.dataSource.applyFilter({featuredBrandId: brandCampaign?.value ? brandCampaign.value : null})
    }

    onProgramFilerChanged(program: RetailerPromoProgram): void {
        this.dataSource.applyFilter({programId: program ? String(program.getId()) : null})
    }

    sendEmailNotification(item: BrandPromoCampaignReport) {
        this.svcApi.getUsers({brandPartnerIds: [item.brandPromoCampaign.brandPartner.id]}).pipe(
            takeUntil(Utils.onDestroy(this)),
            flatMap(users => {
                const dialogConfig = new MatDialogConfig();
                dialogConfig.disableClose = true;
                dialogConfig.autoFocus = true;
                dialogConfig.width = '600px';
                dialogConfig.data = {
                    users: users.data,
                    cpr: item
                }

                return this.svcLayout.openDialogForResult(CampaignReportEmailDialogComponent, dialogConfig)
            })).subscribe(value => this.dataSource.replaceItem(value))
    }

    onBrandCampaignClicked(brandPromoCampaign: BrandPromoCampaign) {
        BrandCampaignEditDialogComponent.open(this.svcLayout, brandPromoCampaign, BRAND_CAMPAIGN_GQL_FIELDS).subscribe()
    }

    approve(data: any) {
        this.doApprove(data.id)
    }

    doCsvExport() {
        const csvData = this.convertToCsv(this.dataSource.data.map(s => s.getData().performanceData))
        const blob = new Blob([csvData], {type: "text/csv;charset=utf-8;"});
        const dwldLink = document.createElement("a");
        const url = URL.createObjectURL(blob);
        dwldLink.setAttribute("href", url);
        dwldLink.setAttribute("download", "campaign_performance.csv");
        dwldLink.style.visibility = "hidden";
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    }

    convertToCsv(objArray: IBrandCampaignPerformance[]): string {
        const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        for (const key of Object.keys(array[0])) {
            if (key in this.headersMap) {
                str += `"${this.headersMap[key]}",`;
            }
        }
        str = str.slice(0, -1); // to remove the last comma
        str += '\r\n';

        return array.reduce((str, next) => {
            for (const key of Object.keys(next)) {
                if (key in this.headersMap) {
                    str += `"${next[key]}",`;
                }
            }
            str = str.slice(0, -1); // to remove the last comma
            str += '\r\n';
            return str;
        }, str);
    }

    private performDateRangeSearch() {
        const startDate = this.form.get('start')?.value ? this.form.get('start')?.value : null
        const endDate = this.form.get('end')?.value ? this.form.get('end')?.value : null
        this.dataSource.applyFilter({startDate: startDate, endDate: endDate})
    }

    getReportUrl(report: BrandPromoCampaignReport) {
        if (report) {
            return report.cpr30Url() + "?internal=true"
        }
    }

    onShareUrl(report: BrandPromoCampaignReport) {
        if (report) {
            this.dialog.open(ReportShareUrlComponent, {
                data: {report: report},
            });
        }
    }

    refresh(data: BrandPromoCampaignReport) {
        this.svcLayout.openDialogForResult(ConfirmDialogComponent, {
            data: {
                message: `Are you sure you want to update the report for <strong>${data.brandPromoCampaign.name}</strong>? The report data will be updated.`
            }
        }).subscribe(confirmed => {
            if (confirmed == "positive") {
                this.svcApi.refreshCampaignReport(`${data.id}`).pipe(
                    takeUntil(Utils.onDestroy(this))
                ).subscribe((resp: BrandPromoCampaignReport) => {
                    this.svcLayout.showSnackMessage(resp ? `Campaign report refreshed` : 'Failed to refresh report');
                    if (resp) {
                        console.log(resp)
                    }
                });
            } else {
                console.log("negative")
            }
        })
    }
}

export interface BrandCampaignReportFilter extends CursorFilter {
    cursor: string
    parentCompanyId: string
    featuredBrandId: string
    programId: string
    retailerIds: string[]
    programIds: string[]
    startDate: Date
    endDate: Date
    sort: ColumnSortInfo[]
}

class CampaignReportDataSource extends ModelDataSource<BrandPromoCampaignReport, BrandCampaignReportFilter> {
    constructor(private svcApi: ApiDataService, private component: CampaignReportsListComponent) {
        super({
            columns: [
                {
                    key: 'status',
                    label: 'Status',
                    width: "120px",
                    sortable: true,
                    valueReader: (item: BrandPromoCampaignReport) => {
                        return item.cprStatus;
                    }
                },
                {
                    key: 'retailer',
                    label: 'Retailer',
                    width: "70px",
                    sortable: true,
                    valueReader: (item: BrandPromoCampaignReport) => {
                        return item.brandPromoCampaign.retailer.retailer_id;
                    }
                },
                {
                    key: 'program',
                    label: 'Program',
                    sortable: true,
                    valueReader: (item: BrandPromoCampaignReport) => {
                        return item.brandPromoCampaign.promoProgram.name;
                    }
                },
                {
                    key: 'campaignName',
                    label: 'Campaign',
                    sortable: true,
                    valueReader: (item: BrandPromoCampaignReport) => {
                        return item.brandPromoCampaign.name;
                    }
                },
                {
                    key: 'cost',
                    label: 'Cost',
                    sortable: true,
                    valueReader: (item: BrandPromoCampaignReport) => {
                        return item.performanceData.campaignCost;
                    }
                },
                {
                    key: 'sales',
                    label: 'Sales',
                    sortable: true,
                    valueReader: (item: BrandPromoCampaignReport) => {
                        return item.performanceData.conversionSalesValue;
                    }
                },
                {
                    key: 'inc_sales',
                    label: 'Inc. Sales',
                    sortable: true,
                    valueReader: (item: BrandPromoCampaignReport) => {
                        return item.performanceData.conversionValue;
                    }
                },
                {
                    key: 'rr-roas',
                    label: 'FF-Value',
                    sortable: true,
                    valueReader: (item: BrandPromoCampaignReport) => {
                        return item.performanceData.fullFunnelValue;
                    }
                },
                {
                    key: 'ffiroas',
                    label: 'FFiROAS',
                    sortable: true,
                    valueReader: (item: BrandPromoCampaignReport) => {
                        return item.performanceData.roasMultiplier + "x";
                    }
                },
                {
                    key: 'lastUpdate',
                    label: 'Last update',
                    width: "100px",
                    valueReader: (item: BrandPromoCampaignReport) => {
                        return item.updatedAt;
                    }
                },
                {
                    key: 'viewCount',
                    label: 'View Count',
                    width: "50px",
                    valueReader: (item: BrandPromoCampaignReport) => {
                        return item.viewCount;
                    }
                },
                {
                    key: 'actions',
                    label: '',
                    width: "80px",
                    valueReader: (item: BrandPromoCampaignReport) => {
                        return "";
                    }
                },
            ]
        });
    }

    loadData(dataFilter: BrandCampaignReportFilter): Observable<CursorFeed<BrandPromoCampaignReport>> {
        return this.svcApi.getBrandCampaignReports(dataFilter);
    }


    setSort(column: TableColumnSpec, dir: SortDirection) {
        const compare = (a, b, path, isNumeric = false) => {
            const valA = path.split('.').reduce((obj, key) => obj?.[key], a.getData());
            const valB = path.split('.').reduce((obj, key) => obj?.[key], b.getData());

            if (isNumeric) {
                return dir === SortDirection.Asc ? valA - valB : valB - valA;
            }

            return dir === SortDirection.Asc ? valA?.localeCompare(valB) : valB?.localeCompare(valA);
        };

        const sortMap = {
            "status": {path: "cprStatus", isNumeric: false},
            "retailer": {path: "brandPromoCampaign.retailer.retailer_id", isNumeric: false},
            "program": {path: "brandPromoCampaign.promoProgram.name", isNumeric: false},
            "campaignName": {path: "brandPromoCampaign.name", isNumeric: false},
            "cost": {path: "performanceData.campaignCost", isNumeric: true},
            "inc_sales": {path: "performanceData.conversionValue", isNumeric: true},
            "rr-roas": {path: "performanceData.fullFunnelValue", isNumeric: true},
            "sales": {path: "performanceData.conversionSalesValue", isNumeric: true},
            "ffiroas": {path: "performanceData.roasMultiplier", isNumeric: true},
        };

        const sortConfig = sortMap[column.key];
        if (sortConfig) {
            this.loadedData.sort((a, b) => compare(a, b, sortConfig.path, sortConfig.isNumeric));
            this.notifyDataChanged(true);
        }
    }
}

import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ApiDataService} from "../../../../services/api-data.service";
import gql from "graphql-tag";
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';

@Component({
    selector: 'app-enrolled-products',
    templateUrl: './enrolled-products.component.html',
    styleUrls: ['./enrolled-products.component.scss']
})
export class EnrolledProductsComponent implements OnInit {
    displayedColumns: string[] = ['segmentName', 'productName', 'upcCode', 'brandPartnerName', 'isEnrolled'];
    dataSource = new MatTableDataSource<EnrolledProduct>([]);

    @ViewChild(MatSort, {static: true}) sort: MatSort;

    PRODUCT_ENROLLMENT_STATUS_QUERY = gql`
    query productEnrollment($scheduleId: ID!) {
        productEnrollment(scheduleId: $scheduleId) {
            segmentName
            productName
            upcCode
            brandPartnerName
            isEnrolled
        }
    }
    `;

    constructor(private activatedRoute: ActivatedRoute, private svcApi: ApiDataService) {}

    ngOnInit(): void {
        const scheduleId = this.activatedRoute.snapshot.paramMap.get("scheduleId") || null;
        if (scheduleId == null) {
            return;
        }

        this.svcApi.rawQuery({
            query: this.PRODUCT_ENROLLMENT_STATUS_QUERY,
            variables: { scheduleId: scheduleId },
            fetchPolicy: 'no-cache',
        }).subscribe(result => {
            const productEnrollments = result.data["productEnrollment"];
            this.dataSource.data = productEnrollments.sort((a, b) => a.brandPartnerName.localeCompare(b.brandPartnerName) && !a.isEnrolled);
            this.dataSource.sort = this.sort;
        });
    }

    export() {
        const dataToExport = this.dataSource.data;
        const replacer = (key, value) => value === null ? '' : value;
        const header = this.displayedColumns;
        const csv = dataToExport.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
        csv.unshift(header.join(','));
        const csvArray = csv.join('\r\n');

        const a = document.createElement('a');
        const blob = new Blob([csvArray], {type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        a.href = url;
        a.download = 'Enrolled-Products.csv';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }
}

export interface EnrolledProduct {
    segmentName: string
    productName: string
    upcCode: string
    brandPartnerName: string
    isEnrolled: boolean
}
